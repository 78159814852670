import React, { useState, useMemo } from 'react';
import { format } from "date-fns";
import {
    Box,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    TextField,
} from "@material-ui/core";
import { useGetCampaignsQuery } from "../../store/pardotApiSlice";

const Campaigns = ({ filter, setFilter }) => {

    const { data: campaigns } = useGetCampaignsQuery();
    const [page, setPage] = useState(0);

    const activeFilter = useMemo(() => {
        if (filter.length < 3)
            return "";

        setPage(0);

        return filter.toLowerCase();
    }, [filter]);    

    const rowsPerPage = 10;

    const sortedCampaigns = useMemo(() => {
        if (!campaigns)
            return [];

        let sorted = [...campaigns];

        return sorted.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);
    }, [campaigns]);    

    const filteredCampaigns = useMemo(() => {

        let filtered = sortedCampaigns;

        if (activeFilter !== "")
            filtered = filtered.filter(f => f.name.toLowerCase().includes(activeFilter));

        return filtered;

    }, [activeFilter, sortedCampaigns]);

    const displayedCampaigns = useMemo(() => {

        let displayed = filteredCampaigns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return displayed;
    }, [filteredCampaigns, page]); 

    return (
        <>
            <Box>
                <TextField
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    variant="outlined"
                    size="small"
                    label="filter"
                />
            </Box>            
            {!campaigns && <h2 style={{ margin: '10px' }}>Loading ...</h2>}
            {campaigns && campaigns.length === 0 && <h2 style={{ margin: '10px' }}>No entries</h2>}
            {campaigns && campaigns.length > 0 &&
                <Table size="small">
                    <TableHead>
                        <TableRow style={{height:'37px'}}>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Members</TableCell>
                            <TableCell>Updated</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedCampaigns.map(c =>
                            <TableRow key={c.id}>
                                <TableCell>{c.id}</TableCell>
                                <TableCell>{c.name}</TableCell>
                                <TableCell>{c.memberCount}</TableCell> 
                                <TableCell>{format(new Date(c.updatedAt), "dd/MM/yy HH:mm")}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                count={filteredCampaigns.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, newPage) => setPage(newPage)}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            }          
        </>
    )
};

export default Campaigns;
